if (ZenviaChat) {
  throw new Error('Skipping duplicated zenvia-chat.js loading!');
}
var ZenviaChat = function(_channelId, _useCookie, _userId) {
  var host = 'https://chat.zenvia.com';

  var channelId = _channelId;
  var useCookie = _useCookie;
  var userId = _userId;

  var chatType;
  var chatWidth;
  var chatHeight;
  var chatColor;
  var keyword;
  var keywordFormat;
  var ballonMessage;

  var container;

  var isBuilt = false;
  var isRoomReady = false;
  var openWindowUponRoomCreation = false;
  var roomId;
  var skipFirstMessage = false;
  var brand;
  var shouldRecreate = false;

  var isWindowOpen = false;
  var isStarted = false;

  var device = detectDevice();

  function detectDevice() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }

  var extra = undefined;
  var website = buildWebsiteData(window.location.href)
  website.referrer = buildWebsiteData(document.referrer);

  function buildWebsiteData(url) {
    var regex = new RegExp('^[^:]+://([^?:/#]+)(:[0-9]+)?(/[^?#]*)?([?]([^#]*))?(#.*)?$');
    var data = {
      url: url,
      hostname: '',
      path: '',
      rawQueryParams: '',
      queryParams: {},
    }

    try {
      var matches = regex.exec(url);
      if(matches[1] !== undefined) {
        data.hostname = matches[1];
      }
      if(matches[3] !== undefined) {
        data.path = matches[3];
      }
      if(matches[4] !== undefined && matches[4].length > 0) {
        data.rawQueryParams = matches[4].substr(1);
        data.queryParams = buildQueryParams(matches[5]);
      }
    } catch(e) {}

    return data;
  }

  function buildQueryParams(rawQuery) {
    var queryParams = {};
    var items = rawQuery.split("&");
    items.forEach(function (item) {
      if(item != '') {
        var pair = item.split("=");
        queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
    });
    return queryParams;
  }

  function resolveId(useCookie, _uid) {
    if ('embeddedConfiguration' in brand) {
      if (useCookie === undefined) {
        useCookie = brand.embeddedConfiguration.continuousConversation === undefined ? true : brand.embeddedConfiguration.continuousConversation;
      }
    }

    if (!_uid && useCookie !== false) {
      _uid = getCookie("_zid");
    }

    if (!_uid) {
      _uid = fingerprint(Math.random());
      if (useCookie !== false && !getCookie("_zid")) {
        document.cookie = "_zid=" + _uid + "; path=/; SameSite=Lax; Secure";
      }
    }
    return _uid;
  }

  function fingerprint(key, seed) {
    var remainder, bytes, h1, h1b, c1, c2, k1, i;

    remainder = key.length & 3; // key.length % 4
    bytes = key.length - remainder;
    h1 = seed || Date.now();
    c1 = 0xcc9e2d51;
    c2 = 0x1b873593;
    i = 0;

    while (i < bytes) {
      k1 =
        ((key.charCodeAt(i) & 0xff)) |
        ((key.charCodeAt(++i) & 0xff) << 8) |
        ((key.charCodeAt(++i) & 0xff) << 16) |
        ((key.charCodeAt(++i) & 0xff) << 24);
      ++i;

      k1 = ((((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16))) & 0xffffffff;
      k1 = (k1 << 15) | (k1 >>> 17);
      k1 = ((((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16))) & 0xffffffff;

      h1 ^= k1;
      h1 = (h1 << 13) | (h1 >>> 19);
      h1b = ((((h1 & 0xffff) * 5) + ((((h1 >>> 16) * 5) & 0xffff) << 16))) & 0xffffffff;
      h1 = (((h1b & 0xffff) + 0x6b64) + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16));
    }

    k1 = 0;

    switch (remainder) {
      case 3:
        k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
        break;
      case 2:
        k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
        break;
      case 1:
        k1 ^= (key.charCodeAt(i) & 0xff);

        k1 = (((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
        k1 = (k1 << 15) | (k1 >>> 17);
        k1 = (((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
        h1 ^= k1;
    }

    h1 ^= key.length;

    h1 ^= h1 >>> 16;
    h1 = (((h1 & 0xffff) * 0x85ebca6b) + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 13;
    h1 = ((((h1 & 0xffff) * 0xc2b2ae35) + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16))) & 0xffffffff;
    h1 ^= h1 >>> 16;

    return h1 >>> 0;
  }

  function toggle(event) {
    if (isWindowOpen) {
      close();
    } else {
      open();
    }
    return this;
  }

  function close(event) {
    openWindowUponRoomCreation = false;
    isWindowOpen = false;
    container.classList.remove('znv-is-active');
    return this;
  }

  function open(event) {
    if (!isRoomReady && !isWindowOpen) {
      openWindowUponRoomCreation = true;
      return this;
    }
    isWindowOpen = true;
    if (!isStarted) {
      buildChat();
    }
    container.classList.add('znv-is-active');
    redirectWhenOnMobile();
    var optInLaunch = container.getElementsByClassName('znv-opt-in');
    if (optInLaunch.length > 0) {
      container.removeChild(optInLaunch[0]);
    }
    return this;
  }

  function buildOptIn() {
    if (container.getElementsByClassName('znv-opt-in').length === 0 && !isStarted) {
      // Opt in
      var optInContainer = document.createElement('div');
      optInContainer.classList.add('znv-opt-in');

      var optInLaunch = document.createElement('a');
      optInLaunch.classList.add('znv-opt-in-link');
      optInLaunch.innerHTML = keyword;
      optInLaunch.style.color = chatColor;
      optInLaunch.addEventListener('click', function(e) {
        // Prevent double click
        if (this.getAttribute('disabled')) {
          return false;
        }
        this.setAttribute('disabled', 'disabled');

        buildChat();
        container.removeChild(container.getElementsByClassName('znv-opt-in')[0]);
      });

      if (ballonMessage) {
        var optInText = document.createElement('p');
        optInText.classList.add('znv-opt-in-text');
        optInText.innerHTML = ballonMessage;

        var optInClose = document.createElement('div');
        optInClose.classList.add('znv-opt-in-close');
        optInClose.addEventListener('click', close);

        optInContainer.appendChild(optInClose);
        optInContainer.appendChild(optInText);
      }
      optInContainer.appendChild(optInLaunch);

      container.appendChild(optInContainer);
    }
  }

  function buildChat() {
    isStarted = true;

    if (chatType === 'room') {
      container.classList.add('znv-is-inline');
      container.classList.add('znv-is-active');
    }

    if (shouldRedirect()) {
      sendFirstMessage();
      return;
    }

    // Create chat container
    if (container.getElementsByTagName('iframe').length === 0) {
      var chatContainer = document.createElement('div');
      chatContainer.classList.add('znv-chat-container');
      var chatStyle = '';
      if (chatWidth !== undefined) {
        chatStyle += 'width: ' + chatWidth + ';';
      }
      if (chatHeight !== undefined) {
        chatStyle += 'height: ' + chatHeight + ';';
      }
      chatContainer.setAttribute('style', chatStyle);

      var chatIframe = document.createElement('iframe');
      chatIframe.addEventListener('load', function () {
        setTimeout(sendFirstMessage, 1000);
      });
      chatIframe.classList.add('znv-iframe');
      chatIframe.setAttribute('src', host + '/chat/' + roomId + '?utm_campaign=embedded&utm_source=' + window.location.host + '&source=embedded');
      chatIframe.setAttribute('frameborder', '0');
      chatIframe.setAttribute('width', '100%');
      chatIframe.setAttribute('height', '100%');

      var chatHeader = document.createElement('div');
      chatHeader.classList.add('znv-chat-header');
      chatHeader.style.backgroundColor = chatColor;

      var chatHeaderClose = document.createElement('div');
      chatHeaderClose.classList.add('znv-chat-header-close');
      chatHeaderClose.addEventListener('click', close);

      chatHeader.appendChild(chatHeaderClose);

      chatContainer.appendChild(chatHeader);
      chatContainer.appendChild(chatIframe);

      container.appendChild(chatContainer);
    }
  }

  function loadStylesheet() {
    if (!ZenviaChat.loadedStyles) {
      var stylesheet = document.createElement("link");
      stylesheet.setAttribute("rel", "stylesheet");
      stylesheet.setAttribute("type", "text/css");
      stylesheet.setAttribute("href", "https://static.zenvia.com/embed/css/zenvia-chat.min.css");
      document.getElementsByTagName("head")[0].appendChild(stylesheet);
      ZenviaChat.loadedStyles = true;
    }
  }

  function createRoom(onSuccess) {
    website.extra = extra;
    var data = {
      from: channelId,
      to: [userId],
      content: [],
      website: website
    };
    var url = host + "/api/rooms/messages";
    postRequest(data, url, function (room) {
      roomId = room.roomId;
      var request = new XMLHttpRequest();
      request.onreadystatechange = function() {
        if (this.readyState === 4) {
          if (this.status === 200 || this.status === 304) {
            room = JSON.parse(request.responseText);
            if (room.sessionStarted) {
              skipFirstMessage = true;
            }
            onSuccess(room);
          }
        }
      }

      request.open("GET", host + "/api/rooms/" + roomId + '?enriched=true', true);
      request.send();
    });
  }

  function shouldRedirect() {
    return device === 'mobile' && chatType !== 'room';
  }

  function redirectWhenOnMobile() {
    if (shouldRedirect()) {
      window.location.href = host + '/chat/' + roomId + '?utm_campaign=embedded&utm_source=' + window.location.host + '&source=embedded';
      return;
    }
  }

  function sendFirstMessage() {
    if (!skipFirstMessage) {
      skipFirstMessage = true;
      var data = {};
      if (keywordFormat == "json") {
        data.dataKey = keyword;
      } else {
        data.text = keyword;
      }
      var url = host + "/api/rooms/" + roomId + "/messages";
      postRequest(data, url, redirectWhenOnMobile, redirectWhenOnMobile);
    } else {
      redirectWhenOnMobile();
    }
  }

  function postRequest(data, url, onSuccess, onError) {
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 201) {
          if(onSuccess) {
            onSuccess(JSON.parse(request.responseText));
          }
        } else {
          if(onError) {
            onError(JSON.parse(request.responseText));
          }
        }
      }
    };
    request.open("POST", url, true);
    request.setRequestHeader('Content-Type', 'application/json');
    request.send(JSON.stringify(data));
  }

  function getCookie(key) {
    var name = key + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkIsBuilt() {
    if (isBuilt) {
      throw new Error('This ZenviaChat instance have already been built!');
    } else {
      return false;
    }
  }

  function containerId(_containerId) {
    checkIsBuilt();
    if(_containerId) {
      container = document.getElementById(_containerId);
      if (!container) {
        container = document.createElement('div');
        container.id = _containerId;
        if (document.body) {
          document.body.appendChild(container);
        } else {
          document.documentElement.appendChild(container);
        }
      }
      container.classList.add('znv-chat');
    }
    return this;
  }

  function useCookie(_useCookie) {
    checkIsBuilt();
    useCookie = _useCookie;
    return this;
  }

  function user(_userId) {
    checkIsBuilt();
    userId = _userId;
    return this;
  }

  function embedded(_chatType) {
    checkIsBuilt();
    chatType = _chatType;
    return this;
  }

  function button(_keyword) {
    checkIsBuilt();
    keyword = _keyword;
    return this;
  }

  function format(_keywordFormat) {
    checkIsBuilt();
    keywordFormat = _keywordFormat;
    return this;
  }

  function width(_chatWidth) {
    checkIsBuilt();
    chatWidth = _chatWidth;
    return this;
  }

  function height(_chatHeight) {
    checkIsBuilt();
    chatHeight = _chatHeight;
    return this;
  }

  function color(_chatColor) {
    checkIsBuilt();
    chatColor = _chatColor;
    return this;
  }

  function buttonColor(_buttonColor) {
    checkIsBuilt();
    buttonColor = _buttonColor;
    return this;
  }

  function message(_ballonMessage) {
    checkIsBuilt();
    ballonMessage = _ballonMessage;
    return this;
  }

  function extraData(data) {
    checkIsBuilt();
    if (data && typeof data !== 'function') {
      extra = data;
    }
    return this;
  }

  function recreate() {
    shouldRecreate = true

    return this;
  }


  return {

    toggle: toggle,

    close: close,

    open: open,

    useCookie: useCookie,

    user: user,
    uid: user,

    containerId: containerId,

    embedded: embedded,

    button: button,

    format: format,

    width: width,

    height: height,

    color: color,

    message: message,

    extraData: extraData,

    recreate: recreate,

    build: function(_containerId) {
      checkIsBuilt();
      loadStylesheet();

      if (!channelId) {
        throw new Error('Please, inform channelId to load a chatbot.');
      }

      if (_containerId) {
        containerId(_containerId);
        isBuilt = true;
      }

      var chatInstance = this;
      var requestBrand = new XMLHttpRequest();
      requestBrand.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200 || this.status === 304) {
            brand = JSON.parse(requestBrand.responseText);

            // Find container to embed chat
            if (!container) {
              var i = 0;
              var _containers = document.getElementsByClassName('znv-chat');
              if (_containers) {
                for (; i < _containers.length; i++) {
                  if (!_containers[i].zenviaChat) {
                    container = _containers[i];
                    isBuilt = true;
                    break;
                  }
                }
              }
              if (!container) {
                containerId('znv-chat-auto-' + i);
                isBuilt = true;
              }
            }
            if (container.zenviaChat && !shouldRecreate) {
              throw new Error('It is not possible to use the same div id for more than one ZenviaChat!');
            } else {
              container.zenviaChat = chatInstance;
            }

            userId = resolveId(useCookie, userId || container.dataset.uid || container.dataset.user);

            var debug = window.location.hash == '#debug' || window.location.search && window.location.search.match(/[?&]debug(?=$|&)/);
            if (debug) {
              console.log('Chat-Id: ' + channelId + '\nUser-Id: ' + userId);
            }

            createRoom(function (room) {
              chatType = chatType || container.dataset.embedded;
              if (chatType === undefined) {
                chatType = skipFirstMessage ? 'button' : 'button+keyword';
              }

              chatWidth = chatWidth || container.dataset.width;
              if (chatWidth === undefined && chatType == 'room') {
                chatWidth = '100%';
              }

              if (!chatWidth) {
                if (room.extra.brand.styles.dimension) {
                  chatWidth = room.extra.brand.styles.dimension.width;
                }
                if (!chatWidth) {
                  chatWidth = '400px';
                }
              }

              chatHeight = chatHeight || container.dataset.height;
              if (chatHeight === undefined && chatType == 'room') {
                chatHeight = '100%';
              }

              if (!chatHeight) {
                if (room.extra.brand.styles.dimension) {
                  chatHeight = room.extra.brand.styles.dimension.height;
                }
                if (!chatHeight) {
                  chatHeight = '567px';
                }
              }

              chatColor = chatColor || container.dataset.color;
              if (!chatColor) {
                if (room.extra.brand.styles.colors.button) {
                  chatColor = room.extra.brand.styles.colors.button;
                } else {
                  chatColor = room.extra.brand.styles.colors.primary;
                }
                if (!chatColor) {
                  chatColor = '#333';
                }
              }

              ballonMessage = ballonMessage || container.dataset.message;
              keyword = keyword || container.dataset.button || 'Começar';
              keywordFormat = keywordFormat || container.dataset.format;

              // Shows chat inline
              if (chatType === 'room') {
                keywordFormat = keywordFormat || 'json';
                buildChat();
                isRoomReady = true;
              }
              // Chat needs to be actived by the host page
              else if (chatType === 'buttonless') {
                keywordFormat = keywordFormat || 'json';
                isRoomReady = true;
              }
              // Shows floating button
              else if (chatType === 'button' || chatType === 'button+keyword') {
                if (ballonMessage) {
                  chatType = 'button+keyword';
                }
                // Float button
                var float = document.createElement('div');
                float.classList.add('znv-float-button');
                float.style.backgroundColor = chatColor;

                // When the button is clicked, the hovering chat will be shown
                if (chatType === 'button') {
                  keywordFormat = keywordFormat || 'json';
                  float.addEventListener('click', toggle);
                  container.appendChild(float);
                  isRoomReady = true;
                }
                // When the button is clicked, the keyword will be shown on the first time
                else {
                  keywordFormat = keywordFormat || 'text';
                  float.addEventListener('click', function (event) {
                    buildOptIn();
                    container.classList.toggle('znv-is-active');
                  });
                  isRoomReady = true;
                  container.appendChild(float);
                  if (ballonMessage) {
                    float.click();
                  }
                }
              }
              //Shows nothing because it is a invalid chatType
              else {
                throw new Error('Unsupported ZenviaChat type: ' + chatType);
              }
              if (openWindowUponRoomCreation) {
                openWindowUponRoomCreation = false;
                open();
              }
            });
          }
        }
      }
      requestBrand.open("GET", host + "/api/backend/brand/name/" + channelId, true);
      requestBrand.send();

      return this;
    },

    destroy: function destroy() {
      if (isBuilt && container && container.zenviaChat === this) {
        close();
        while (container.hasChildNodes()) {
          container.removeChild(container.lastChild);
        }
        container.zenviaChat = undefined;
      }
    }

  };

};
